<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-one-third is-offset-one-third">
            <div id="login-card" class="card">
              <div class="card-content">
                <img src="@/assets/gps_logo.png" alt="Gps logo" width="400px" class="text-center">

                <!-- <form
                  id="externalLogin"
                  novalidate="true"
                  @submit.prevent="onLogin()"
                >
                  <b-field>
                    <b-input
                      ref="externalLogin"
                      v-model="username"
                      name="username"
                      placeholder="Username or email"
                      type="text"
                      icon="user"
                    />
                  </b-field>
                  <b-field>
                    <b-input
                      v-model="password"
                      name="password"
                      password-reveal
                      placeholder="Password"
                      type="password"
                      icon="lock"
                    />
                  </b-field>
                  <button
                    :class="{ 'is-loading': isLoading }"
                    class="button is-primary is-fullwidth"
                    type="submit"
                  >
                    Login
                  </button>
                </form> -->
                <div
                  class="buttons is-flex is-flex-direction-column is-justify-content-center is-align-items-center px-5 py-3"
                >
                  <b-button
                    type="is-primary"
                    icon-pack="fab"
                    icon-left="windows"
                    expanded
                    @click="onSsoLogin"
                  >
                    Continue with Microsoft
                  </b-button>
                  <b-button
                    type="is-info"
                    icon-pack="fas"
                    icon-left="mobile-screen-button"
                    disabled
                    expanded
                  >
                    NC application
                  </b-button>
                  <b-button icon-pack="fas" icon-left="arrow-right-from-bracket" expanded @click="openGLPI">
                    Report issues
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as msal from '@azure/msal-browser'

export default {
  data () {
    return {
      username: '',
      password: '',
      isLoading: false,
      msalInstance: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  mounted () {
    // this.$refs.externalLogin.focus()
    this.msalInstance = new msal.PublicClientApplication({
      auth: {
        clientId: process.env.VUE_APP_CLIENT_ID,
        authority:
          `https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}`,
        redirectUri: '/'
      }
    })
  },
  methods: {
    ...mapActions(['login', 'ssoLogin']),
    onLogin: async function () {
      this.isLoading = true
      try {
        await this.login({
          username: this.username,
          password: this.password
        })
        this.$http.defaults.headers.common.User = this.user.displayName
        this.$router.push(this.$route.query.redirect || '/')
      } catch (error) {
        this.$buefy.notification.open({
          type: 'is-danger',
          message: error.message,
          duration: 5000,
          hasIcon: true
        })
      }
      this.isLoading = false
    },
    onSsoLogin: async function () {
      await this.msalInstance.loginPopup()
      if (this.msalInstance.getAllAccounts().length === 0) return
      const account = this.msalInstance.getAllAccounts()[0]
      if (!account) return
      const request = {
        scopes: ['email', 'offline_access', 'openid', 'profile'],
        account
      }
      const aadAccount = await this.msalInstance.acquireTokenSilent(request)
      try {
        await this.ssoLogin({
          token: aadAccount.accessToken,
          user: aadAccount.account
        })
        this.$http.defaults.headers.common.User = this.user.displayName
        this.$router.push(this.$route.query.redirect || '/')
      } catch (error) {
        this.$buefy.notification.open({
          type: 'is-danger',
          message: error.message,
          duration: 5000,
          hasIcon: true
        })
      }
    },
    openGLPI () {
      window.open('https://glpi.fr-gps.com/', '_blank')
    }
  }
}
</script>

<style scoped>
#login-card {
  margin-top: -50px;
}

.sso-login {
  /* margin-top: 0.55rem; */
  margin-top: 3rem;
  margin-bottom: 3rem;
}
</style>
