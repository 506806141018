import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'
import constructQueryString from '@/helpers/constructQueryString'
// import contentDisposition from 'content-disposition'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/report'

const report = axios.create({
  baseURL
})

interceptorsSetup(report)

export default class Report {
  static async getAll () {
    const { data: result } = await report.get(`/`)
    return result
  }

  static async getRecentReport () {
    const { data: result } = await report.get(`/recentreport`)
    return result
  }

  // static async getErrors (reportId) {
  //   const { data: result } = await report.get(`/${reportId}/errors/`)
  //   return result
  // }

  // static async getReportFile (id) {
  //   const result = await report.get(`/${id}/download`, {
  //     responseType: 'blob'
  //   })
  //   const { parameters } = contentDisposition.parse(result.headers['content-disposition'])

  //   return {
  //     filename: parameters.filename,
  //     data: result.data
  //   }
  // }

  // static async getReportType (customerCode) {
  //   const { data: result } = await report.get(`/reportType/${customerCode}`)
  //   return result
  // }

  // static async create (formData) {
  //   const { data: result } = await report.post('/', formData, {
  //     headers: { 'content-type': 'multipart/form-data' }
  //   })
  //   return result
  // }

  // static async edit (reportId, formData) {
  //   const { data: result } = await report.put(`/${reportId}`, formData, {
  //     headers: { 'content-type': 'multipart/form-data' }
  //   })
  //   return result
  // }

  // static async importExcelFile (formData, config) {
  //   const { data: result } = await report.post(`/importExcelFile`, formData, config)
  //   return result
  // }

  // static async deleteFiles (reportId) {
  //   const { data: result } = await report.delete(`/${reportId}`)

  //   return result
  // }
  static async generateReport (data) {
    const { data: result } = await report.post(`/`, data)

    return result
  }

  static async getParameters (query) {
    const queryString = constructQueryString(query)
    const { data: result } = await report.get(`/parameters?${queryString}`)
    return result
  }

  static async getReport (reportId, data) {
    const result = await report.get(`/${reportId}`,
      {
        params: {
          customerCode: data.customerCode,
          customerSoldTo: data.customerSoldTo,
          endDate: data.endDate
        },
        responseType: 'blob'
      })
    return result
  }

  static async hasReport (customerCode, customerSoldTo, endDate) {
    const result = await report.get(`/hasreport/${customerCode}/${customerSoldTo}/${endDate}`)
    return result
  }

  static async returnData (data) {
    const { data: result } = await report.post(`/returnData`, data)

    return result
  }

  static async getTemplate () {
    const result = await report.get('/template', {
      responseType: 'blob'
    })
    return result
  }
}
