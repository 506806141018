<template>
  <div class="modal-card" style="width: auto; min-height: 70vh">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Batch add new devices
      </p>
    </header>
    <section class="modal-card-body">
      <div class="mb-5">
        <b-field label="Serial number(s)">
          <b-input v-model="serialBuffer" />
          <p class="control">
            <b-button icon-left="arrow-down" @click="saveNumber" />
          </p>
        </b-field>
        <b-field label="No serial number">
          <b-input v-model="noSerialBuffer" type="number" min="0" />
          <p class="control">
            <b-button icon-left="plus" @click="saveNoNumber" />
          </p>
        </b-field>
        <div v-for="(c, index) in serialArray" :key="index">
          <span class="is-flex is-align-items-center">
            <div class="mr-2">
              <strong>{{ index + 1 }}</strong> : {{ c }}
            </div>
            <b-button icon-left="xmark" class="is-small" @click="removeNumber(index)" />
          </span>
        </div>
      </div>
      <div class="mb-5">
        <b-field label="Type">
          <b-radio
            v-model="type"
            native-value="plant"
          >
            Plant
          </b-radio>
          <b-radio
            v-model="type"
            native-value="customer"
          >
            Customer
          </b-radio>
          <b-radio
            v-model="type"
            native-value="producer"
          >
            Producer
          </b-radio>
        </b-field>
      </div>
      <div class="mb-5">
        <div v-if="type === 'plant'">
          <b-field label="Plant">
            <multiselect
              v-model="assignedTo"
              :options="$lodash.orderBy(plants, ['name'])"
              :multiple="false"
              :close-on-select="true"
              placeholder="Pick plant..."
              :custom-label="getLabel"
              track-by="id"
            />
          </b-field>
        </div>
        <div v-if="type === 'customer'">
          <b-field label="Customer">
            <multiselect
              v-model="assignedTo"
              :options="customers"
              :multiple="false"
              :close-on-select="true"
              placeholder="Pick customer..."
              :custom-label="getLabel"
              track-by="id"
            />
          </b-field>
        </div>
        <div v-if="type === 'producer'">
          <b-field label="Producer">
            <multiselect
              v-model="assignedTo"
              :options="$lodash.orderBy(plants, ['name'])"
              :multiple="false"
              :close-on-select="true"
              placeholder="Pick producer..."
              :custom-label="getLabel"
              track-by="id"
            />
          </b-field>
        </div>
      </div>
      <div v-if="codeGenerated" style="min-height: 150px">
        <p v-for="(c, index) in registrationCodes" :key="c">
          {{ index + 1 }} registration code: <strong>{{ c }}</strong>
        </p>
      </div>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons ml-auto">
        <b-button type="is-info" :disabled="!type ||!assignedTo || serialArray.length == 0" @click="generateCode">
          Generate code(s)
        </b-button>
        <b-button type="is-primary" :loading="isLoading" :disabled="registrationCodes.length == 0" @click="submit">
          Submit
        </b-button>
      </div>
    </footer>
  </div>
</template>

<script>
import crypto from 'crypto'
import DeviceAdmin from '@/services/v2/deviceAdmin'

export default {
  props: {
    plants: {
      default: () => [],
      type: Array
    },
    customers: {
      default: () => [],
      type: Array
    }
  },
  data () {
    return {
      isLoading: false,
      type: 'plant',
      codeGenerated: false,
      assignedTo: '',
      serialArray: [],
      serialBuffer: '',
      noSerialBuffer: 0,
      registrationCodes: [],
      device: {
        serialNumber: '',
        registrationCode: '',
        plantId: '',
        customerId: '',
        producerId: ''
      }
    }
  },
  watch: {
    type () {
      this.codeGenerated = false
      this.assignedTo = ''
    },
    assignedTo () {
      this.codeGenerated = false
    }
  },
  methods: {
    getLabel ({ id, name }) {
      return `${id} - ${name}`
    },
    generateCode () {
      const currentTimestamp = new Date().toISOString()
      var randomValue = ''
      var combinedInput = ''
      var hash = ''
      this.registrationCodes = []
      for (let i in this.serialArray) {
        randomValue = Math.random().toString()
        combinedInput = this.assignedTo.id + currentTimestamp + randomValue + i
        hash = crypto.createHash('sha256').update(combinedInput).digest('hex')
        this.registrationCodes.push(hash.slice(0, 6))
      }
      this.codeGenerated = true // Ensure canvas is rendered
    },
    saveNumber () {
      if (this.serialBuffer && this.serialBuffer !== '') {
        this.serialArray.push(this.serialBuffer)
        this.serialBuffer = ''
      }
    },
    saveNoNumber () {
      if (this.noSerialBuffer >= 0) {
        for (let i = 0; i < this.noSerialBuffer; i++) {
          this.serialArray.push('No serial number provided')
        }
        this.noSerialBuffer = 0
      }
    },
    removeNumber (target) {
      if (this.serialArray && this.serialArray.length > target) {
        this.serialArray.splice(target, 1)
        if (this.registrationCodes && this.registrationCodes.length > target) {
          this.registrationCodes.splice(target, 1)
        }
      }
    },
    async submit () {
      switch (this.type) {
        case 'plant':
          this.device.plantId = this.assignedTo.id
          break
        case 'customer':
          this.device.customerId = this.assignedTo.id
          break
        case 'producer':
          this.device.producerId = this.assignedTo.id
          break
      }
      try {
        this.isLoading = true
        for (const code in this.registrationCodes) {
          this.device.registrationCode = this.registrationCodes[code]
          if (this.serialArray[code] === 'No serial number provided') {
            this.device.serialNumber = ''
          } else {
            this.device.serialNumber = this.serialArray[code]
          }
          await DeviceAdmin.createByRigstrationCode(this.device)
        }
        this.$buefy.toast.open({ type: 'is-primary', message: 'Done !', duration: 3000 })
        this.$emit('on-close')
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message || error, duration: 3000 })
      }
      this.isLoading = false
    }
  }
}

</script>
