<template>
  <form @submit.prevent="submit">
    <div class="modal-card" style="min-height: 750px">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Stock validation
        </p>
      </header>
      <section class="modal-card-body">
        <b-field>
          <multiselect
            v-model="selectedCustomer"
            :options="customers"
            :multiple="false"
            :close-on-select="true"
            placeholder="Pick customer shipped to..."
            :custom-label="(obj) => obj.id + ' - ' + obj.name"
            track-by="name"
            style="min-width: 400px;"
          />
        </b-field>
        <b-field>
          <multiselect
            v-model="selectedCustomerSoldTo"
            :options="customers"
            :multiple="false"
            :close-on-select="true"
            placeholder="Pick customer sold to..."
            :custom-label="(obj) => obj.id + ' - ' + obj.name"
            track-by="name"
            style="min-width: 400px;"
          />
        </b-field>
        <b-field>
          <b-datepicker
            v-model="reportEndDate"
            name="date"
            icon="calendar-alt"
            placeholder="End date"
            :date-formatter="(date) => $dayjs(date).format('DD/MM/YYYY')"
          />
        </b-field>
        <b-field>
          <input
            id="fileUpload"
            type="file"
            hidden
            @change="loadTextFromFile"
          >
          <b-button class="btn" @click="chooseFiles">
            {{ $t('pages.customer_forecast.upload_csv') }}
          </b-button>
          <b-button class="btn" type="is-primary" @click="getTemplate">
            {{ $t('pages.customer_forecast.get_csv_template') }}
          </b-button>
        </b-field>
        <b-field>
          <b-message
            v-model="isActive"
            type="is-success"
            aria-close-label="Close message"
          >
            The file look good
          </b-message>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-field>
          <b-button
            position="is-right"
            type="is-info"
            icon-left="play"
            native-type="submit"
            :disabled="isDisabled()"
          >
            Send
          </b-button>
          <b-checkbox v-model="nothing">
            Nothing to declare
          </b-checkbox>
        </b-field>
      </footer>
    </div>
  </form>
</template>
<script>
import { saveAs } from 'file-saver'
import Report from '@/services/v2/report'

export default {
  components: {
  },
  props: {
    customers: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      selectedCustomer: null,
      selectedCustomerSoldTo: null,
      reportEndDate: null,
      datas: [],
      isActive: false,
      nothing: false
    }
  },
  watch: {
    datas (newDatas, oldDatas) {
      if (newDatas.length > 0 && this.checkData(newDatas)) { this.isActive = true }
    }
  },

  mounted: function () {
  },
  methods: {
    isDisabled () {
      if (!this.selectedCustomer && !this.selectedCustomerSoldTo) { return true } else {
        if (this.reportEndDate == null) {
          return true
        }
        if (this.nothing === true) {
          return false
        }
        if (this.datas.length > 0) {
          return false
        }
        return true
      }
    },
    getTemplate: async function () {
      try {
        const result = await Report.getTemplate()
        saveAs(result.data, 'template_forecast.csv')
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
      }
    },
    chooseFiles: function () {
      document.getElementById('fileUpload').click()
    },
    loadTextFromFile: async function (el) {
      const file = el.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        this.datas = this.parseCVS(event.target.result)
      }
      await reader.readAsText(file)
    },
    checkData: function (datas) {
      for (const i in datas) {
        if (datas[i] === undefined || datas[i].barcode === undefined) {
          this.$buefy.notification.open({ type: 'is-danger', message: this.$t('pages.customer_forecast.error_csv'), duration: 5000, hasIcon: true })
          return false
        }
      }
      return true
    },
    parseCVS: function (data) {
      let allTextLines = data.split(/\r\n|\n/)

      const delimiter = allTextLines[0].includes(';') ? ';' : ','

      let headers = ['barcode', 'column1', 'column2', 'column3', 'column4', 'column5', 'column6', 'column7', 'column8', 'column9', 'column10', 'column11', 'column12', 'column13', 'column14', 'column15', 'column16', 'column17', 'column18', 'column19', 'column20', 'column21', 'column22', 'column23', 'column24', 'column25', 'column26', 'column27', 'column28', 'column29', 'column30', 'column31', 'column32', 'column33', 'column34', 'column35']
      let lines = []
      let values = []

      for (var i = 1; i < allTextLines.length; i++) {
        let data = allTextLines[i].split(delimiter)

        if (data.length === 1) continue
        values.push(data[0]?.toLowerCase())
        let tmp = {}

        for (let j = 0; j < headers.length; j++) {
          tmp[headers[j].toLowerCase()] = data[j]?.toLowerCase()
        }
        lines.push(tmp)
      }

      const ifDuplicated = this.checkDuplicate(values)

      if (ifDuplicated) return this.$buefy.toast.open({ type: 'is-danger', message: 'Duplicated barcode', duration: 3000 })

      return lines
    },

    checkDuplicate (arr) {
      return new Set(arr).size !== arr.length
    },

    async submit () {
      if (this.nothing === true) {
        this.$emit('submit', { data: 'nothing', customerCode: this.selectedCustomer?.id || '-1', customerSoldTo: this.selectedCustomerSoldTo?.id || '-1', dateEnd: this.$dayjs(this.reportEndDate).format('DDMMYYYY'), generationDate: this.$dayjs().format('DDMMYYYY') })
        return
      }
      if (this.datas.length === 0 || this.reportEndDate == null || (this.selectedCustomer == null && this.selectedCustomerSoldTo == null)) {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Pleas fill all the fields', duration: 3000 })
      }
      for (var i = 0; i < this.datas.length; i++) {
        this.datas[i].id_report = `${this.selectedCustomer?.id || '-1'}_${this.selectedCustomerSoldTo?.id || '-1'}_${this.$dayjs(this.reportEndDate).format('DDMMYYYY')}`
      }
      try {
        this.$emit('submit', { data: this.datas, customerCode: this.selectedCustomer?.id || '-1', customerSoldTo: this.selectedCustomerSoldTo?.id || '-1', dateEnd: this.$dayjs(this.reportEndDate).format('DDMMYYYY') })
        return
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message, duration: 3000 })
      }
    }
  }
}
</script>

<style type="css" scoped>
  .modal-card-body {
    min-height: 50vh;
  }
</style>
